<template>
    <div class="app-layout">
        <div class="show-destop">
            <nav-bar/>
            <div id="mainContent" class="container-fluid">
                <div class="side-bar">
                    <side-bar></side-bar>
                </div>

                <main id="main">
                    <router-view class="main-view"></router-view>
                </main>
            </div>
            <footer-element/>      
        </div>      
        <div class="show-mobile">
            Download App
        </div>   
    </div>
</template>

<script>
    import SideBar from "../SideBar";
    import NavBar from "../NavBar";
    import FooterElement from "../FooterElement";

    export default {
        name: "AppLayout",

        data() {
            return{
                
            }
        },

        components: {
            FooterElement,
            NavBar,
            SideBar,
        },

        computed: {
            isLoading() {
                return this.$store.getters.isLoading
            },
        },

        mounted() {

        },

        created() {

        },
        
        methods: {
            
        }
    }
</script>
<style scoped>
    #main .main-view{
        padding-top: 25px;
        width: 1088px;
        /* height: 100vh; */
    }

    #mainContent{
    }

    #main{
        width: calc(100% - 50px);
        display: flex;
        justify-content: center;
        margin-left: 50px;
    }
</style>