<template>
  <div id="lesson">
    <div class="webview">
      <iframe
        allow="microphone *;clipboard-read *; clipboard-write *"
        :src="test.content_url"
        frameborder="0"
        allowfullscreen
        width="100%"
        height="100%"
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestDetailPreview",
  components: {},
  title: "TestDetailPreview",

  // props: ['test'],

  data() {
    return {
      test: JSON.parse(this.$route.query.test),
    };
  },

  created() {
    window.addEventListener("message", this.handleMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.handleMessage);
  },

  mounted() {},

  computed: {},

  methods: {
    handleMessage(event) {
      if (event.data.type == "close_iframe") {
        const path = "/subject/" + this.course_id + "/" + this.class_id;
        this.$router.push(path);
      }
    },
  },
};
</script>

<style scoped>
#lesson {
  /* margin-right: 24px; */
  padding: 0 20px;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {
  display: none;
}
.header {
  height: 32px;
  margin-bottom: 16px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.webview {
  box-shadow: 4px 0px 24px rgba(0, 0, 0, 0.25);
  background: #f8fafc;
  border-radius: 24px;
  /* height: 738px; */
  height: calc(100% - 48px);
  overflow: hidden;
  margin-top: 24px;
}
.igs-btn {
  height: 32px;
  width: 47px;
  background: #ffffff;
}
.igs-btn.btn-menu {
  width: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}
.path {
  letter-spacing: 0.02em;
  color: #00afff;
  font-weight: 500;
  font-size: 16px;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.path-1 {
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 16px;
  color: #f8fe5f;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
