<template>
    <div id="lesson-package">
        <div class="right">
            <div class="header">
                <div style="flex: 1">
                    <div style="display: flex; align-items: center; gap: 5px;">
                        <p>Lesson {{ current_lesson_index }}</p>
                        <img v-if="desc" @click="showDesc()" src="/themes/web/assets/images/icon/icon-info.svg" alt="">
                    </div>
                    <h4>{{ current_lesson_name }}</h4>
                </div>
                <div v-if="teaching_file_url" style="display: flex; justify-content: flex-end; margin-right: 20px;">
                    <button class="btn-file" @click="openSupplementFile(teaching_file_url, teaching_file_name)">
                        Supplement File
                    </button>
                </div>
                <router-link :to="course_id ? 
                { path: '/preview-course?course_id=' + course_id} : { path: '/preview-course?module_id=' + module_id }">
                    <img src="/themes/web/assets/images/icon/icon-closed-3.svg" alt="">
                </router-link>
                <!-- <div @click="goBack()">
                    <img src="/themes/web/assets/images/icon/icon-closed-3.svg" alt="">
                </div> -->
            </div>
            <div class="content">
                <svg v-if="is_loading" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    width="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;top: 50%; position: absolute; left: 50%; transform: translateX(-100%);" xml:space="preserve">
                    <rect x="0" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML"
                        values="5;21;5" 
                        begin="0s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML"
                        values="13; 5; 13"
                        begin="0s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="10" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML"
                        values="5;21;5" 
                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML"
                        values="13; 5; 13"
                        begin="0.15s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x="20" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML"
                        values="5;21;5" 
                        begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML"
                        values="13; 5; 13"
                        begin="0.3s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                </svg>

                
                <div class="list-package">
                    <div v-for="lsp, index in lessonPackages" :key="lsp.id" :class="lsp.lock != 1 ? 'item-package' : 'item-package lock'">
                        <div>
                            <!-- <img :src="lsp.package_img" alt=""> -->
                        </div>
                        <div class="info">
                            <div>
                                <!-- <img v-if="lsp.lock == 1" src="/themes/web/assets/images/icon/lesson-package/icon-lock.svg" alt="">
                                <img v-else-if="lsp.status == 'completed'" src="/themes/web/assets/images/icon/lesson-package/icon-check.svg" alt="">
                                <img v-else-if="lsp.status == 'overdue'" src="/themes/web/assets/images/icon/lesson-package/icon-fail.svg" alt=""> -->
                                <!-- <img v-else-if="lsp.status == 'notstart'" src="/themes/web/assets/images/icon/lesson-package/icon-lock.svg" alt=""> -->
                                <!-- <img v-else src="/themes/web/assets/images/icon/lesson-package/icon-fire.svg" alt=""> -->
                                <!-- <p>Lesson Activity {{ index+1 }}</p> -->
                            </div>
                            <h5>{{ lsp.package_name }}</h5>
                        </div>
                        <div :class="{ 
                            // completed: lsp.status == 'completed',
                            // overdue: lsp.status == 'overdue',
                            // submitted: lsp.status == 'submitted',
                            // inprogress: lsp.status == 'inprogress',
                            // inprogress: lsp.status == 'on-going',
                            // resubmit: lsp.status == 'resubmit',
                            // todo: lsp.status == 'todo',
                            // notstart: lsp.status == 'notstart',
                            status: true 
                        }"
                        >
                            <!-- <p v-if="lsp.status == 'completed'">Completed</p>
                            <p v-if="lsp.status == 'overdue'">Overdue</p>
                            <p v-if="lsp.status == 'submitted'">Submitted</p>
                            <p v-if="lsp.status == 'on-going'">On going</p>
                            <p v-if="lsp.status == 'inprogress'">In-Progress</p>
                            <p v-if="lsp.status == 'resubmit'">Need to Resubmit</p>
                            <p v-if="lsp.status == 'todo'">Todo</p>
                            <p v-if="lsp.status == 'notstart'">Not started</p> -->
                            <img @click="gotoItem(lsp)" src="/themes/web/assets/images/icon/lesson-package/icon-play.png" alt="">
                        </div>
                    </div>

                    <!-- <div class="item-package">
                        <div>
                            <img src="/themes/web/assets/images/icon/lesson-package/Thumbnail.png" alt="">
                        </div>
                        <div class="info">
                            <div>
                                <img src="/themes/web/assets/images/icon/lesson-package/icon-fail.svg" alt="">
                                <p>Lesson Activity 1</p>
                            </div>
                            <h5>Lesson Package Name</h5>
                        </div>
                        <div class="status overdue">
                            <p>Overdue</p>
                            <img src="/themes/web/assets/images/icon/lesson-package/icon-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-package">
                        <div>
                            <img src="/themes/web/assets/images/icon/lesson-package/Thumbnail.png" alt="">
                        </div>
                        <div class="info">
                            <div>
                                <img src="/themes/web/assets/images/icon/lesson-package/icon-fire.svg" alt="">
                                <p>Lesson Activity 1</p>
                            </div>
                            <h5>Lesson Package Name</h5>
                        </div>
                        <div class="status submitted">
                            <p>Submitted</p>
                            <img src="/themes/web/assets/images/icon/lesson-package/icon-play.png" alt="">
                        </div>
                    </div>

                    <div class="item-package lock">
                        <div>
                            <img src="/themes/web/assets/images/icon/lesson-package/Thumbnail.png" alt="">
                        </div>
                        <div class="info">
                            <div>
                                <img src="/themes/web/assets/images/icon/lesson-package/icon-lock.svg" alt="">
                                <p>Lesson Activity 1</p>
                            </div>
                            <h5>Lesson Package Name</h5>
                        </div>
                        <div class="status notstart">
                            <p>Not start</p>
                            <img src="/themes/web/assets/images/icon/lesson-package/icon-play.png" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="footer" v-if="pre_lesson_id != 0 || next_lesson_id != 0 ">
                <div class="f-left">
                    <div v-if="pre_lesson_id != 0">
                        <div>
                            <button @click="preLesson()" class="igs-btn"><img src="/themes/web/assets/images/icon/arrow-left.svg" alt=""></button>
                        </div>
                        <div>
                            <p>Previous Lesson</p>
                            <h4>Lesson {{ pre_lesson_index }}: {{ pre_lesson_name }}</h4>
                        </div>
                    </div>
                   
                </div>
                <div class="f-right">
                    <div v-if="next_lesson_id != 0">
                        <div>
                            <p>Next Lesson</p>
                            <h4>Lesson {{ next_lesson_index }}: {{ next_lesson_name }}</h4>
                        </div>
                        <div>
                            <button @click="nextLesson()" class="igs-btn"><img src="/themes/web/assets/images/icon/arrow-right.svg" alt=""></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <theme-desc-modal class="" ref="themeDesc"></theme-desc-modal>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {getLessonPackage} from "../../models/preview";
import themeDescModal from "../modal/Learning/themeDescModal.vue";

export default {
    name: "PreviewLesson",
    components: {themeDescModal},
    title: 'PreviewLesson',

    props: ['lessonId'],

    data() {
        return{
            lessonPackages: [],
            course_id: this.$route.query.course_id,
            module_id: this.$route.query.module_id,
            lesson_id: this.$route.query.lesson_id,
            next_lesson_id : 0,
            next_lesson_name : '',
            next_lesson_index: 0,

            pre_lesson_id : 0,
            pre_lesson_name : '',
            pre_lesson_index: 0,

            current_lesson_index: 0,
            current_lesson_id: 0,
            current_lesson_name: '',

            desc: '',

            is_loading: true,
            teaching_file_name: '',
            teaching_file_url: '',
        }
    },

    created() {
        this.$store.commit("setLoading", false);
        setTimeout(() => {
            this.getLessonPackage(this.lesson_id);
        }, 800);
    },

    mounted(){

    },

    computed: {
        
    },

    methods: {
        openSupplementFile(url, name){
            const fileExtension = name.split('.').pop();
            console.log("File extension:", fileExtension);
            let targetUrl = "";
            switch (fileExtension) {
                case "docx":
                case "doc":
                case "xlsx":
                case "xls":
                case "pptx":
                case "ppt":
                    targetUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(url)}`;
                    break;
                default:
                    targetUrl = url;
            }

            window.open(targetUrl, "_blank");
        },
        async showDesc(){
            const cfm = await this.$refs.themeDesc.show({
                type: 3,
                // lang: this.lang,
                desc: this.desc,
            });
            if (!cfm) {
                return false;
            }
        },
        goBack(){
            this.$router.go(-1);
        },
        gotoItem(lsp){
            console.log(lsp);
            // this.$router.push({ name: 'lesson_detail', params: { asm } });
            // this.$router.push({ path: '/preview-lesson-package/'+this.course_id+'-'+this.module_id+'-'+lsp.lesson_id+'-'+lsp.id+'-'+0 })

            this.$router.push({
                path: '/preview-lesson-package',
                query: {
                    course_id: this.course_id,
                    module_id: this.module_id,
                    lesson_id: lsp.lesson_id,
                    lesson_package_id: lsp.id,
                    item_id: 0,
                }
            });
        },
        preLesson(){
            this.$router.push({
                path: '/preview-lesson',
                query: {
                    course_id: this.course_id,
                    module_id: this.module_id,
                    lesson_id: this.pre_lesson_id
                }
            });
            this.getLessonPackage(this.pre_lesson_id);
        },
        nextLesson(){
            this.$router.push({
                path: '/preview-lesson',
                query: {
                    course_id: this.course_id,
                    module_id: this.module_id,
                    lesson_id: this.next_lesson_id
                }
            });
            this.getLessonPackage(this.next_lesson_id);
        },
        getLessonPackage(lesson_id){
            this.is_loading = true
            axios.all([
                getLessonPackage({
                    course_id: this.course_id,
                    lesson_id: lesson_id,
                    module_id: this.module_id,
                }),
            ]).then(axios.spread((response) => {
                if(response.data.code != 0){
                    return;
                }
                this.lessonPackages = response.data.results.lesson_packages
                this.next_lesson_id = response.data.results.next_lesson_id
                this.next_lesson_name = response.data.results.next_lesson_name
                this.next_lesson_index = response.data.results.next_lesson_index

                this.pre_lesson_id = response.data.results.pre_lesson_id
                this.pre_lesson_name = response.data.results.pre_lesson_name
                this.pre_lesson_index = response.data.results.pre_lesson_index

                this.current_lesson_index = response.data.results.current_lesson_index
                this.current_lesson_name = response.data.results.current_lesson_name
                this.current_lesson_id = response.data.results.current_lesson_id 

                this.desc = response.data.results.desc
                this.teaching_file_name = response.data.results.teaching_file_name
                this.teaching_file_url = response.data.results.teaching_file_url
                this.is_loading = false
            }));
        }
    }
}
</script>

<style scoped>
    svg path,
    svg rect{
        fill: var(--Jelly-Light, #A560EB);
    }
    .f-left h4, .f-right h4{
        color: var(--Jelly-Light, #A560EB);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.32px;
    }
    .f-left p, .f-right p{
        color: var(--Liquorice-Light, #777);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: 0.24px;
    }
    .f-left > div, .f-right > div{
        display: flex;
        gap: 24px;
        align-items: center;
    }
    .item-package.lock{
        opacity: 0.25;
        background: var(--Jelly-Very-Light, #EAD4FF);
    }
    .item-package.lock .status img{
        -webkit-filter: grayscale(100%); /* Trình duyệt webkit (chrome, safari, ...)*/
        filter: grayscale(100%);
    }
    .status img{
        width: 64px;
        height: 64px;
        cursor: pointer;
    }
    .igs-btn{
        border: 1px solid var(--squid-black, #1D1D1F);
        width: 68px;
        height: 48px;
    }  
    .f-left .igs-btn{
        background: var(--Jelly-Very-Light, #EAD4FF);
    }  
    .status{
        display: flex;
        gap: 16px;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
    }
    .status p{
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.8px;
    }
    .status.completed p{
        color: var(--Kiwi-Light, #58CC02);
    }
    .status.inprogress p{
        color: var(--Kiwi-Light, #FDD20D);
    }
    .status.todo p {
        color: var(--Kiwi-Light, #A560EB);
    }
    .status.submitted p{
        color: var(--Kiwi-Light, #02A6E7);
    }
    .status.resubmit p{
        color: var(--Kiwi-Light, #FC7A1B);
    }

    .status.overdue p{
        color: var(--Kiwi-Light, #FF3D54);
    }
    .status.notstart p{
        color: var(--Kiwi-Light, #777);
    }
    .info{
        margin-left: 16px;
        text-wrap: nowrap;
    }
    .info > div{
        display: flex;
        gap: 8px;
        align-items: center;
    }
    .info h5{
        margin-top: 4px;
        color: var(--squid-black, #1D1D1F);
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.4px;
    }
    .info p{
        color: var(--Liquorice-Dark, #4C4C4C);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.32px;
    }
    .item-package>div:nth-child(1) img{
        width: 64px;
        height: 64px;
    }
    .list-package{
        border-radius: 24px;
        border: 2px solid var(--Cloud-Light, #DDD);
        background: var(--Creme-Light, #FFF);
        overflow: hidden;
    }
    .item-package:nth-child(1){
        border-top: none;
    }
    .item-package:last-child{
        border-bottom: none;
    }
    .item-package{
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-top: 1px solid #A35CDB;
        /* border-bottom: 1px solid #A35CDB; */
        background: var(--Creme-Light, #FFF);
    }
    .content{
        padding: 0 24px;
        flex: 1;
        overflow-y: auto;
        padding-bottom: 16px;
    }
    .header{
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        padding: 24px;
        background: var(--Creme-Light, #FFF);
        box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(12px);
    }
    .footer{
        display: flex;
        justify-content: space-between;
        padding: 16px;
        background: rgba(255, 255, 255, 0.80);
        box-shadow: 0px -8px 24px 0px rgba(0, 0, 0, 0.16);
        backdrop-filter: blur(4px);
        align-items: center;
        gap: 50px;
    }
    .header>div p{
        color: var(--Squid-Light, #403D3D);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0.32px;
    }
    .header>div img{
        cursor: pointer;
    }
    .header>div h4{
        color: var(--squid-black, #1D1D1F);
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
    }
    #lesson-package >.right{
        background: #FFFFFF;
        height: 100%;
        /* width: calc(100% - 56px); */
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
        border-radius: 24px 0px 0px 24px;
        overflow: auto;
        flex: 1;
        display: flex;
        flex-direction: column;
    }
</style>
