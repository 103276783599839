<template>
    <div id="home" style="overflow: auto; height: 100vh;  scrollbar-width: none;" @click="handleClickOutside">
        <div v-if="isModal" class="modal1"></div>
        <div class="loading" id="loading" v-if="isLoading">
            <img class="spinner" src="/themes/web/assets/images/icon/loadding.png" alt="" />
        </div>
        <div class="header">
            <div class="hello">
                <div>
                    <span>Hi 👋 </span>
                    <img src="" alt="">
                    <span>{{ user_info.last_name +" "+ user_info.first_name }},</span>
                </div>
                <!-- <h2>Let's study!</h2> -->
            </div>
            <div class="control">
                <!-- <div class="search">
                    <form>
                        <img class="icon-search" src="/themes/web/assets/images/icon/icon-search.svg" alt="">
                        <input class="form-search" v-model="valueSearch" type="text" name=""
                            :placeholder="$t('Search anything')" id="q" />
                        <button @click.prevent="valueSearch != '' ? clickSearch() : ''" :class="valueSearch != '' ? 'btn-search' : 'btn-search disable'">{{ $t('Search Now') }}</button>
                    </form>
                </div> -->
                <!-- <div class="item-control">
                    <img class="icon-search" src="/themes/web/assets/images/icon/help.svg" alt="">
                </div>
                <div class="item-control">
                    <div @click="showPopupNoti()" style="width: 100%; height: 100%">
                        <span class="number-noti">3</span>
                        <img style="display: flex; margin: 7px auto;" class="icon-search"
                            src="/themes/web/assets/images/icon/noti.svg" alt="">
                    </div>

                    <div class="popup-noti" v-if="show_popup_noti">
                        <h5>Notifications</h5>
                        <div class="noti-new">
                            <h6>New</h6>
                            <div class="list-noti">
                                <div class="item-noti">
                                    <div class="avatar-noti">
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti">
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="open-noti">
                            <p @click="openNotiModal()">Open Notification Center <img
                                    src="/themes/web/assets/images/icon/circle-arrow-right-1.svg" alt=""></p>
                        </div>
                        <div class="noti-old">
                            <h6>Older</h6>
                            <div class="list-noti">
                                <div class="item-noti">
                                    <div class="avatar-noti">
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti">
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                                <div class="item-noti">
                                    <div class="avatar-noti">
                                        <img src="/themes/web/assets/images/icon/avatar.svg" alt="">
                                    </div>
                                    <div>
                                        <div style="display: flex; justify-content: space-between;">
                                            <p class="name">Admin</p>
                                            <p class="ago">5 minutes ago</p>
                                        </div>
                                        <p class="content-noti">sent a notification to your email. Let’s take a look.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="item-control" ref="searchContainer1">
                    <img @click="clickSearch()" class="icon-search" src="/themes/web/assets/images/icon/icon-search1.svg" alt="">
                </div>
            </div>
        </div>
        <div class="courses-list">
            <div class="course-title">Your courses</div>
            <div class="course-programs">
                <div :class="program_id_filter == 0 ? 'program-card active' : 'program-card'" @click="changeProgram(0)" ref="program_all">
                    All Programs
                </div>
                
                <div v-for="programitem in sortedProgramData" v-bind:key="programitem.id" :ref="'program_'+programitem.id" :class="program_id_filter == programitem.id ? 'program-card active' : 'program-card'" @click="changeProgram(programitem)">
                    {{ programitem.name }}
                </div>

                <!-- <p>Filter by Program: </p>
                <div class="filter">
                    <div :class="{ 'input-filter': true }" @click="showSlection()">
                        <p :class="program_id_filter != 0 ? 'p-active' : ''">{{ program_id_filter == 0 ? 'Select a Programs'
                            :
                            program_name_filter }}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
                            <path d="M1.5 1.5L8.5 8.5L15.5 1.5" stroke="#CFCFCF" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div class="list-choice" v-if="filter">
                        <div class="item-choice"  @click="changeProgram(0)">
                            All
                        </div>
                        <div v-for="programitem in programData" v-bind:key="programitem.id"
                            @click="changeProgram(programitem)" class="item-choice">
                            <p>{{ programitem.name }}</p>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div v-if="isLoading == false">
            <div v-if="(courses.length > 0 || temp_courses.length > 0)" class="content">
                <div class="subject-card" v-for="courseItem in courses" :key="courseItem.class_id"
                    @click="gotoSuject(courseItem.course_id, courseItem.class_id)">
                    <div class="header" 
                        @mouseenter="checkOverflow($event, courseItem)" 
                        @mouseleave="resetOverflow(courseItem)">
                        <marquee class="title" v-if="courseItem.isOverflowing" width="100%">{{ courseItem.class_name }}</marquee>
                        <div v-else class="title">{{ courseItem.class_name }}</div>
                    </div>
                    <div class="subject-img">
                        <img :src="courseItem.illustration_url" alt="">
                    </div>

                    <div v-if="courseItem.status_class == 'not-started' && timeRemaining(courseItem) <= 86400" class="progress not-start">
                        <div class="progress-load" v-bind:style="{ width: '100%' }"></div>
                        <span class="precent">START IN {{ formatTime(timeRemaining(courseItem)) }}</span>
                    </div>
                    <div v-else-if="courseItem.status_class == 'not-started'" class="progress not-start">
                        <div class="progress-load" v-bind:style="{ width: '100%' }"></div>
                        <span class="precent">Not Started</span>
                    </div>
                    <div v-else-if="courseItem.status_class == 'todo'" class="progress to-do">
                        <div class="progress-load" v-bind:style="{ width: '100%' }"></div>
                        <span class="precent">TO DO</span>
                    </div>
                    <div v-else-if="courseItem.status_class == 'completed'" class="progress complete">
                        <div class="progress-load" v-bind:style="{ width: '100%' }"></div>
                        <span class="precent">COMPLETED</span>
                    </div>
                    <div v-else-if="courseItem.status == 'expired'" class="progress expired">
                        <div class="progress-load" v-bind:style="{ width: '100%' }">
                        </div>
                        <span class="precent"> {{ courseItem.process }}/{{ courseItem.total }} MODULE - EXPIRED</span>
                    </div>

                    <div v-else class="progress inprogress">
                        <div class="progress-load"
                            v-bind:style="{ width: courseItem.process != 0 && courseItem.total != 0 ? courseItem.process / courseItem.total * 100 + '%' : '5%' }">
                        </div>
                        <span class="precent">{{ courseItem.process }}/{{ courseItem.total }}</span>
                    </div>
                    <div>
                        <p class="studing">{{ courseItem.course_name }}</p>
                    </div>
                    <p style="color: #777777; margin-top: 17px; font-size: 12px; text-align: center;">{{ courseItem.is_structured ? "Structured learning" : "Unstructured learning"}}</p>
                    <!-- <div class="div-btn">
                        <button @click="gotoSuject(courseItem.course_id)" class="igs-btn">Start</button>
                    </div> -->
                </div>
            </div>
            <div v-else class="courses-list-empty">
                <img class="icon" style="cursor: pointer;" src="/themes/web/assets/images/icon/course_empty.svg" alt="">
                <span class="title">There are no courses to show.<br>
                    Please come back later.</span>
            </div>
        </div>
        <div v-else>
            <div class="content">
                <div class="subject-card" v-for="i in random_times" :key="i" style="opacity: 0.8;">
                    <div class="header">
                        <div class="title" style="background: #ccc; width: 100px; height: 20px;"></div>
                    </div>
                    <div class="subject-img">
                        <img src="/themes/web/assets/images/default-course.png" alt="">
                    </div>
                    <div class="progress expired">
                        <div class="progress-load" v-bind:style="{ width: '100%' }">
                        </div>
                        <span class="precent"></span>
                    </div>
                    <p class="studing"  style="background: #ccc; width: 60px; height: 25px; margin: auto;"></p>
                    <p style="margin: 10px auto; background: #ccc; width: 100px; height: 15px;"></p>
                </div>
            </div>
        </div>
        <div class="footer">
            <!-- <div class="today_classes">
                <h3 class="title">Today’s Classes</h3>
                <div class="line"></div>
                <div class="list-classes">
                    <div v-if="liveClassData.length != 0">
                        <div v-for="live_class in liveClassData" :key="live_class.id"
                            :class="live_class.status == 1 ? 'item-class comming' : (live_class.status == 2 ? 'item-class' : 'item-class finish')">
                            <div class="">
                                <div class="info-class">
                                    <div class="calender-class">
                                        <div class="day">{{ getPeriodOfDay(live_class.time_start) }}</div>
                                        <div class="time">{{ formatUnitTime(live_class.time_start_str) }}</div>
                                    </div>
                                    <div class="details-class">
                                        <p class="name-subject">{{ live_class.subject }}</p>
                                        <p class="teacher">Teacher: {{ live_class.teacher }}</p>
                                        <p class="time-start"><img src="/themes/web/assets/images/icon/icon-timer.svg"
                                                alt=""> <span> {{ durationStudy(live_class) }} minutes</span></p>
                                    </div>
                                </div>
                            </div>
                            <div v-if="live_class.status == 2" class="">
                                <button @click="btnJoinClass(live_class.start_url)" class="igs-btn">Join</button>
                            </div>
                            <div v-if="live_class.status == 1" class="status">
                                <p class="">Start in</p>
                                <p class="" style="white-space: nowrap;">
                                    <countdown :live_class="live_class" :key="live_class.id" />
                                </p>
                            </div>
                            <div v-if="live_class.status == 4" class="">
                                <p class="status">FINISHED</p>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="no-classes">
                            There is no class today.
                        </p>
                    </div>
                </div>
            </div> -->

            <div style="display: flex; gap: 30px; justify-content: space-between;">

                <div class="assignments" >
                    <h3 class="title">Assignments</h3>
                    <div class="line"></div>
                    <div class="list-assignments">
                        <div v-if="assignments.length != 0">
                            <div v-for="(asm, index) in assignments" :key="asm.id + asm.class_id"
                                :class="checkToday(asm.end_unix, asm.current_unix) ? 'item-assignment today' : 'item-assignment'">
                                <div class="">
                                    <div class="info-assignment">
                                        <!-- <div class="number" style="margin-right: 20px;">
                                            <p>{{ index + 1 > 10 ? index + 1 : '0' + (index + 1) }}</p>
                                        </div> -->
                                        <div class="details-class">
                                            <p style="font-size: 14px;">{{ asm.course_name }}</p>
                                            <h4 class="name-subject">{{ asm.name }}</h4>
                                            <p class="name-theme">Module {{ asm.module_order }} - Lesson {{
                                                asm.lesson_order
                                            }}</p>
                                            <!-- <p v-if="asm.flex == 0" class="name-unit">Lesson Activity</p>
                                            <p v-if="asm.flex == 1">Flex Assignment</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="info-assignment-right">
                                    <!-- <img v-if="checkToday(asm.end_unix, asm.current_unix)"
                                        src="/themes/web/assets/images/icon/clock-red.svg" alt="">
                                    <img v-else src="/themes/web/assets/images/icon/clock.svg" alt="">
                                    <span v-if="checkToday(asm.end_unix, asm.current_unix)" class="day">TODAY</span>
                                    <span v-else class="day">{{ checkToday1(asm.end_unix, asm.current_unix) }}</span> -->
                                    <img @click="gotoAsignment(asm)" class="btn-play"
                                        src="/themes/web/assets/images/icon/btn-play.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div style="display: flex; justify-content: center; position: relative;">
                                <img style="width: 300px;" src="/images/empty-content.png" alt="">
                                <p style="position: absolute; bottom: 0;">There's 0 assignment</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="assignments">
                    <h3 class="title">Flex Assignments</h3>
                    <div class="line"></div>
                    <div class="list-assignments">
                        <div v-if="flex_assignments.length != 0">
                            <div v-for="(asm, index) in flex_assignments" :key="asm.id + asm.class_id"
                                :class="checkToday(asm.end_unix, asm.current_unix) ? 'item-assignment today' : 'item-assignment'">
                                <div class="">
                                    <div class="info-assignment">
                                        <!-- <div class="number" style="margin-right: 20px;">
                                            <p>{{ index + 1 > 10 ? index + 1 : '0' + (index + 1) }}</p>
                                        </div> -->
                                        <div class="details-class">
                                            <p style="font-size: 14px;">{{ asm.course_name }}</p>
                                            <h4 class="name-subject">{{ asm.asm_name }}</h4>
                                            <p  class="name-theme">Start date: {{ (new Date()).customDate(asm.start_time, user_info.timezone) }} </p>
                                            <p  class="name-theme">End date: {{ (new Date()).customDate(asm.end_time, user_info.timezone) }}</p>
                                            <!-- <p>{{ asm.start_time }}</p> -->

                                            <!-- <p v-if="asm.flex == 0" class="name-unit">Lesson Activity</p>
                                            <p v-if="asm.flex == 1">Flex Assignment</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="info-assignment-right">
                                    <!-- <img v-if="checkToday(asm.end_unix, asm.current_unix)"
                                        src="/themes/web/assets/images/icon/clock-red.svg" alt="">
                                    <img v-else src="/themes/web/assets/images/icon/clock.svg" alt="">
                                    <span v-if="checkToday(asm.end_unix, asm.current_unix)" class="day">TODAY</span>
                                    <span v-else class="day">{{ checkToday1(asm.end_unix, asm.current_unix) }}</span> -->
                                    <img @click="gotoAsignment(asm)" class="btn-play"
                                        src="/themes/web/assets/images/icon/btn-play.png" alt="">
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div style="display: flex; justify-content: center; position: relative;">
                                <img style="width: 300px;" src="/images/empty-content.png" alt="">
                                <p style="position: absolute; bottom: 0;">There's 0 flex assignment</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="assignments">
                    <div style="display: flex; justify-content: space-between; align-items: center;">
                        <h3 class="title">Learning Reminder</h3>
                        <div style="font-size: 12px; padding: 4px; background: #FC7A1B; color:  #fff; border-radius: 8px;">COMING SOON</div>
                    </div>
                    <div class="line"></div>
                    <!-- <p style="font-size: 16px; font-weight: 700; color: #CFCFCF; text-align: center; line-height: 20px;">
                        Exciting news! <br>
                        A new feature is on its way: Learning Reminder <br>
                        Stay tuned for this powerful tool that will help you stay on track with your learning goals. 
                        Comming soon! <br>
                    </p> -->
                    <div style="display: flex; justify-content: center;">
                        <img style="width: 300px;" src="/images/coming-soon.png" alt="">
                    </div>
                </div>

            </div>

        </div>


        <div ref="searchContainer" :class="isSearch ? 'model-search active' : 'model-search'">

            <svg v-if="loadingSearch" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="24px" height="30px" viewBox="0 0 24 30"
                style="enable-background:new 0 0 50 50;top: 50%; position: absolute; left: 50%; transform: translateX(-100%);"
                xml:space="preserve">
                <rect x="0" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0s" dur="0.6s"
                        repeatCount="indefinite" />
                </rect>
                <rect x="10" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.15s" dur="0.6s"
                        repeatCount="indefinite" />
                </rect>
                <rect x="20" y="13" width="4" height="5" fill="#333">
                    <animate attributeName="height" attributeType="XML" values="5;21;5" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                    <animate attributeName="y" attributeType="XML" values="13; 5; 13" begin="0.3s" dur="0.6s"
                        repeatCount="indefinite" />
                </rect>
            </svg>

            <div class="header-search">
                <h4>Search Results</h4>
                <img style="cursor: pointer;" @click="isSearch = !isSearch; isModal = !isModal" class="icon-search"
                    src="/themes/web/assets/images/icon/close-search.svg" alt="">
            </div>

            <div class="search">
                <div class="input-search">
                    <img class="icon-search" src="/themes/web/assets/images/icon/icon-search.svg" alt="">
                    <input   @keyup.enter="valueSearch1 != '' ? clickOnSearchUp() : ''" @keydown.enter="valueSearch1 != '' ? clickOnSearchDown() : ''" class="form-search" type="text" v-model="valueSearch1" />
                </div>
                <div 
                    @click="valueSearch1!='' ? keyUpSearch() : ''" 
                    :class="valueSearch1!='' ? 'btn-search1' : 'btn-search1 disable'">
                    Search
                </div>
            </div>

            <div class="line"></div>

            <div class="option-search">
                <div @click="chooseFilterSearch(1)" :class="{ active: filterSearch.includes(1), selection: true }">
                    All results
                </div>
                <div @click="chooseFilterSearch(2)" :class="{ active: filterSearch.includes(2), selection: true }">
                    Programs
                </div>
                <div @click="chooseFilterSearch(3)" :class="{ active: filterSearch.includes(3), selection: true }">
                    Courses
                </div>
                <div @click="chooseFilterSearch(4)" :class="{ active: filterSearch.includes(4), selection: true }">
                    Assignments
                </div>
                <div @click="chooseFilterSearch(5)" :class="{ active: filterSearch.includes(5), selection: true }">
                    Flex Assignments
                </div>
            </div>

            <div class="content-search">
                <!-- <div v-if="programs_search.length == 0 && courses_search.length == 0 && assignments_search.length == 0">
                    <p class="no-result"> No results found for your search. Please try again with different keywords.</p>
                </div>
                <div v-else> -->
                    <div v-if="(filterSearch.includes(1) || filterSearch.includes(2))"
                        class="item-content-search">
                        <h5>Programs</h5>
                        <div v-if="programs_search.length != 0">
                            <p class="result-search" v-for="p in programs_search" :key="p.id" @click="changeProgram(p)">{{
                            p.name }}</p>
                        </div>
                        <div v-else>
                            <p class="no-result"> No results found for your search. Please try again with different keywords.</p>
                        </div>
                    </div>

                    <div v-if="(filterSearch.includes(1) || filterSearch.includes(3))"
                        class="item-content-search">
                        <h5>Courses</h5>
                        <div v-if="courses_search.length != 0">
                            <p class="result-search" v-for="c in courses_search" :key="c.class_id" @click="gotoSuject(c.course_id, c.class_id)">{{ c.course_name
                            }}</p>
                        </div>  
                        <div v-else>
                            <p class="no-result"> No results found for your search. Please try again with different keywords.</p>
                        </div>
                    </div>

                    <div v-if="(filterSearch.includes(1) || filterSearch.includes(4))"
                        class="item-content-search">
                        <h5>Assignments</h5>
                        <div v-if="assignments_search.length != 0">
                            <p class="result-search" v-for="a in assignments_search" :key="a.id" @click="a.is_lock != 'lock' ? gotoAsignment(a): ''" :style="a.is_lock == 'lock' ? 'background: #CFCFCF; cursor: not-allowed; color: #403D3D !important;' : ''">
                                <img v-if="a.is_lock == 'lock'" src="/themes/web/assets/images/icon/icon-lock-asm.png" alt="">
                                {{a.name }}
                            </p>
                        </div>
                        <div v-else>
                            <p class="no-result"> No results found for your search. Please try again with different keywords.</p>
                        </div>
                    </div>

                    <div v-if="(filterSearch.includes(1) || filterSearch.includes(5))"
                        class="item-content-search">
                        <h5>Flex Assignments</h5>
                        <div v-if="flex_assignments_search.length != 0">
                            <!-- <p class="result-search" v-for="a in flex_assignments_search" :key="a.id" @click="gotoAsignment(a)"> -->
                            <p class="result-search" v-for="a in flex_assignments_search" :key="a.id" @click="a.is_lock != 'lock' ? gotoAsignment(a): ''" :style="a.is_lock == 'lock' ? 'background: #CFCFCF; cursor: not-allowed; color: #403D3D !important;' : ''">
                                <img v-if="a.is_lock == 'lock'" src="/themes/web/assets/images/icon/icon-lock-asm.png" alt="">
                                {{a.asm_name }}
                            </p>
                        </div>
                        <div v-else>
                            <p class="no-result"> No results found for your search. Please try again with different keywords.</p>
                        </div>
                    </div>
                <!-- </div> -->
            </div>
        </div>


        <noti-modal class="" ref="notiModal"></noti-modal>
    </div>
</template>

<script>
import axios from "axios";
import { soundClick } from "../utils/utils";
import { getAllCourse, getProgram, searchLearning, StatusPriority } from "../models/dashboard";
import { getAssignments, EAssignmentStatus } from "../models/assignment";
import { getLiveClass } from "../models/live_class";
// import Countdown from '../components/element/Countdown.vue';
import notiModal from "../components/modal/home/notiModal.vue";
import { formatDate } from '../utils/utils'


export default {
    name: "Welcome",
    title: "Home",

    components: { notiModal },

    data() {
        return {
            EAssignmentStatus,
            random_times: Math.floor(Math.random() * 5) + 1,
            temp_courses: [],
            courses_search: [],
            programs_search: [],
            assignments_search: [],
            flex_assignments_search: [],
            courses: [],
            isLoading: true,
            liveClassData: [],
            programData: [],
            isActive: [],
            assignments: [],
            flex_assignments: [],
            isSearch: false,
            filterSearch: [1],
            valueSearch: "",
            valueSearch1: "",
            filter: false,
            program_id_filter: 0,
            program_name_filter: "",
            show_popup_noti: false,
            loadingSearch: false,
            currentTime: Date.now(),
            isModal: false,
            isOverflowing: false
        }
    },

    created() {
        console.log("user_info: ", this.user_info);
        this.$store.commit("setMenuActive", "home");
        this.fetch();
    },

    mounted() {
        document.addEventListener("click", this.handleDocumentClick);

        setInterval(() => {
            this.currentTime = Date.now();
        }, 1000); 
    },

    beforeDestroy() {
        document.removeEventListener("click", this.handleDocumentClick);
    },


    computed: {
        sortedProgramData() {
            return this.programData.slice().sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        },
        user_info() {
            return this.$store.state.user_info;
        },
    },

    methods: {
        formatDate,
        checkOverflow(event, courseItem) {
            const title = event.currentTarget.querySelector('.title');
            if (title.scrollWidth > title.clientWidth) {
                this.$set(courseItem, 'isOverflowing', true); // Set individual overflow state
            }
        },
        resetOverflow(courseItem) {
            this.$set(courseItem, 'isOverflowing', false); // Reset individual overflow state
        },
        handleClickOutside(event) {
            if (!this.$refs.searchContainer.contains(event.target) && this.$refs.searchContainer1.contains(event.target)) {
                this.isModal = true
                this.isSearch = true; 
                console.log(1);
            }
            else if(!this.$refs.searchContainer.contains(event.target)) {
                this.isModal = false
                this.isSearch = false; 
                console.log(2);
            }
        },
        clickOnSearchUp(){
            $('.btn-search1').removeClass('active');
        },
        clickOnSearchDown(){
            $('.btn-search1').addClass('active');
            $('.btn-search1').click();
        },
        timeRemaining(courseItem) {
            return Math.max(0, (courseItem.unix_start_time - this.currentTime) / 1000);
        },
        formatTime(timeInSeconds) {
            const hours = Math.floor(timeInSeconds / 3600);
            const minutes = Math.floor((timeInSeconds % 3600) / 60);
            const seconds = Math.floor(timeInSeconds % 60);
            return `${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
        },
        pad(value) {
            return value < 10 ? '0' + value : value;
        },
        async openNotiModal() {
            const cfm = await this.$refs.notiModal.show({
                lang: this.lang,
            });
            if (!cfm) {
                return false;
            }
        },
        showPopupNoti() {
            this.show_popup_noti = !this.show_popup_noti;
        },
        gotoAsignment(asm) {
            console.log("sss: ", asm);
            // if (asm.flex == 0) {
            //     this.$router.push({ path: '/lesson/' + asm.course_id + '-' + asm.module_id + '-' + asm.lesson_id + '-' + asm.course_item_id })
            // } else {
            //     this.$router.push({ name: 'flex_detail', params: { asm } });
            // }
            const targetRoute = '/lesson/' + asm.class_id +'/'+asm.course_id+'-'+asm.module_id+'-'+asm.lesson_id+'-'+asm.lesson_package_id+'-'+asm.course_item_id
            this.$router.push({path: targetRoute});
        },
        handleDocumentClick(event) {
            const isOutsideInputFilter = !event.target.closest(".input-filter");
            if (isOutsideInputFilter) {
                this.filter = false;
            }

            const isOutsidePopupNoti = !event.target.closest(".item-control");
            if (isOutsidePopupNoti) {
                this.show_popup_noti = false;
            }
        },
        showSlection(opt) {
            this.filter = true
        },
        chooseFilterSearch(filterIndex) {
            // this.filterSearch = filterIndex

            if (this.filterSearch.includes(filterIndex)) {
                // If already selected, remove it
                this.filterSearch = this.filterSearch.filter(item => item !== filterIndex);
            } else {
                // If not selected, add it
                this.filterSearch.push(filterIndex);
            }
        },
        async clickSearch() {
            this.isSearch = !this.isSearch
            this.isModal = !this.isModal


            // this.loadingSearch = true
            // this.valueSearch1 = this.valueSearch
            // await axios.all([
            //     searchLearning({
            //         value_search: this.valueSearch
            //     }),
            // ]).then(axios.spread((response) => {
            //     if (response.data.code != 0) {
            //         return;
            //     }
                
            //     this.courses_search = response.data.results.lstCourse ? response.data.results.lstCourse : []
            //     this.programs_search = response.data.results.lstProgram ? response.data.results.lstProgram : []
            //     this.assignments_search = response.data.results.lstAssignment ? response.data.results.lstAssignment : []
            //     this.loadingSearch = false

            //     console.log(this.courses_search);
            // }));
        },
        async keyUpSearch() {
            this.loadingSearch = true
            this.valueSearch = this.valueSearch1
            await axios.all([
                searchLearning({
                    value_search: this.valueSearch1
                }),
            ]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    return;
                }
                this.courses_search = response.data.results.lstCourse ? response.data.results.lstCourse : []
                this.programs_search = response.data.results.lstProgram ? response.data.results.lstProgram : []
                this.assignments_search = response.data.results.lstAssignment ? response.data.results.lstAssignment : []
                this.flex_assignments_search = this.assignments_search.filter(item => item.is_flex == 1);
                this.assignments_search = this.assignments_search.filter(item => item.is_flex == 0);

                this.loadingSearch = false
            }));
        },
        checkToday(end_time, current_time) {
            const time = new Date(end_time * 1000)
            const time1 = new Date(current_time * 1000)

            const timeDay = time.getDate();
            const timeMonth = time.getMonth() + 1;
            const timeYear = time.getFullYear();

            const currentDay = time1.getDate();
            const currentMonth = time1.getMonth() + 1;
            const currentYear = time1.getFullYear();

            return timeDay === currentDay && timeMonth === currentMonth && timeYear === currentYear;
        },
        checkToday1(end_time, current_time) {
            const endTime = new Date(end_time * 1000);
            const currentTime = new Date(current_time * 1000);

            const timeYear = endTime.getFullYear();
            const timeMonth = endTime.getMonth();
            const timeDay = endTime.getDate();

            const currentYear = currentTime.getFullYear();
            const currentMonth = currentTime.getMonth();
            const currentDay = currentTime.getDate();

            const timeDiff = endTime - currentTime;
            const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

            if (timeDay - 1 === currentDay && timeMonth === currentMonth && timeYear === currentYear) {
                return `1 DAYS`;
            } else {
                return `${dayDiff} DAYS`;
            }

        },
        checkDue(end_time, current_time) {
            if (end_time >= current_time) {
                return true
            } else {
                return false
            }
        },
        async getAllAssignments() {
            await axios.all([
                getAssignments(),
            ]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    return;
                }
                var flexAsm = response.data.results.filter(item => item.is_flex == 1);
                var Asm = response.data.results.filter(item => item.is_flex != 1);

                Asm = Asm.filter(item => item.status == EAssignmentStatus.ON_GOING || item.status == EAssignmentStatus.INPROGRESS || item.status == EAssignmentStatus.NEED_RESUBMIT);
                this.assignments = Asm ? Asm.slice(0, 3) : []

                flexAsm = flexAsm.filter(item => item.status == EAssignmentStatus.ON_GOING || item.status == EAssignmentStatus.INPROGRESS || item.status == EAssignmentStatus.NEED_RESUBMIT);
                this.flex_assignments = flexAsm.sort((a, b) => a.end_unix - b.end_unix);
                this.flex_assignments = this.flex_assignments ? this.flex_assignments.slice(0, 3) : []


                console.log(this.flex_assignments);
            }));
        },
        getSoundClick() {
            soundClick();
        },
        gotoSuject(courseID, classId) {
            const path = '/subject/' + courseID + '/' + classId
            $('#home').css('transform', 'translateX(-100vw)')
            $('#home').css('transform', 'translateX(-100vw)')
            $('#side-bar').css('display', 'none')

            this.$router.push(path)
            this.getSoundClick()
        },
        fetch(page = 1) {
            this.getAllAssignments();
            this.getAllCourse(0);
            // this.getLiveClass();

            const params = {
                grade_id: this.user_info.grade_id,
            };
            axios.all([
                getProgram(),
            ]).then(axios.spread((response) => {
                if (response.data.code != 0) {
                    return;
                }
                this.programData = response.data.results
                this.programData.forEach(x => {
                    var id = x.id
                    this.isActive.push()
                })
            }));
        },
        getAllCourse(program_id) {
            const params = {
                grade_id: this.user_info.grade_id,
                program_id: program_id
            };
            axios.all([
                getAllCourse(),
            ]).then(axios.spread((report_response) => {
                if (report_response.data.code != 0) {
                    return;
                }

                this.courses = report_response.data.results

                this.courses.sort((a, b) => {
                   
                    if (StatusPriority[a.status_class] !== StatusPriority[b.status_class]) {
                        return StatusPriority[a.status_class] - StatusPriority[b.status_class];
                    }
                    let now = new Date();
                    let dateA = new Date(a.start_date);
                    let dateB = new Date(b.start_date);
                    let diffA = Math.abs(dateA - now);
                    let diffB = Math.abs(dateB - now);
                    if(diffA != diffB){
                        return diffA - diffB;
                    }
                    return a.course_name.localeCompare(b.course_name);
                });
                
                this.temp_courses = report_response.data.results
                // this.courses = [...this.courses, { status: 'expired', course_name: 'ccccc', total: 4, process: 1 }, { status: 'completed', course_name: 'ccccc', total: 4, process: 4 }, { status: 'not-started', course_name: 'as dasdqwd', total: 0, process: 0 }, { status: 'inprogress', course_name: 'as dasdqwd', total: 5, process: 1 },]
                this.isLoading = false
            }));
        },
        getLiveClass() {
            const that = this;
            const date1 = new Date()
            axios.all([getLiveClass({
                year: date1.getFullYear(),
                month: (date1.getMonth() + 1),
                day: date1.getDate()
            })]).then(
                axios.spread((response) => {
                    that.liveClassData = response.data.results.class ? response.data.results.class : []
                    const live_class = []
                    for (var i = 0; i < that.liveClassData.length; i++) {
                        var date = new Date(that.liveClassData[i].time_start * 1000);
                        var d = date.getDate();
                        var m = date.getMonth() + 1;
                        var y = date.getFullYear();

                        if (date1.getMonth() + 1 == m && date1.getFullYear() == y && date1.getDate() == d) {
                            live_class.push(that.liveClassData[i])
                        }
                    }
                    that.liveClassData = live_class
                    // console.log(" that.liveClassData : ",  that.liveClassData , live_class);
                    that.$forceUpdate()
                })
            );
        },
        formatUnitTime(timeVal) {
            var date = new Date(timeVal);
            var options = { hour: 'numeric', minute: 'numeric', hour12: true };
            var time = date.toLocaleTimeString('en-US', options);
            return time
        },
        getPeriodOfDay(timestamp) {
            const date = new Date(timestamp * 1000);
            const hour = date.getHours();
            if (hour < 12) {
                return 'Morning';
            } else {
                return 'Afternoon';
            }
        },
        durationStudy(live_class) {
            var timeDiff = Math.abs(live_class.time_start - live_class.time_end);
            var minutes = Math.floor((timeDiff) / 60);
            console.log((timeDiff / 1000) / 60);
            return minutes;
        },
        changeProgram(program) {
            this.isSearch = false
            this.isModal = false
            this.program_id_filter = program.id
            this.program_name_filter = program.name
            this.courses = this.temp_courses.filter(c => c.program_id == program.id)

            if(program == 0){
                this.courses = this.temp_courses;
                this.program_id_filter = 0 
                this.program_name_filter = ""
            }
        },
        btnJoinClass(url) {
            window.open(url)
        },
    }
};
</script>

<style scoped>
.loader {
    margin: 0 0 2em;
    height: 100px;
    width: 20%;
    text-align: center;
    padding: 1em;
    margin: 0 auto 1em;
    display: inline-block;
    vertical-align: top;
}

/*
        Set the color of the icon
        */
svg path,
svg rect {
    fill: var(--Jelly-Light, #A560EB);
}

.open-noti {
    padding: 16px 24px;
    border-bottom: 1px solid var(--Cloud-Light, #DDD);
    border-top: 1px solid var(--Cloud-Light, #DDD);
}

.open-noti p {
    color: var(--Jelly-Light, #A560EB);
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.list-noti .content-noti {
    color: var(--Squid-Light, #403D3D);
    font-size: 12px;
    font-weight: 200;
    letter-spacing: 0.24px;
}

.list-noti .name {
    color: var(--Squid-Light, #403D3D);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
}

.list-noti .ago {
    color: var(--Liquorice-Light, #777);
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.24px;
}

.item-noti {
    gap: 16px;
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
}

.list-noti .avatar-noti {
    border-radius: 1000px;
    width: 50px;
    height: 40px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.list-noti .avatar-noti img {
    width: 100%;
    height: 100%;
}

.noti-new h6,
.noti-old h6 {
    color: var(--Liquorice-Light, #777);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    padding: 0 24px;
}

.noti-old h6 {
    margin-top: 24px;
}

.popup-noti {
    position: absolute;
    top: 42px;
    width: 320px;
    padding: 24px 0px;
    align-items: flex-start;
    border-radius: 24px;
    background: var(--Creme-Light, #FFF);
    z-index: 3;
    right: 0;
}

.popup-noti h5 {
    color: var(--Jelly-Light, #A560EB);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.48px;
    padding: 0 24px;
    margin-bottom: 24px;
}

.number-noti {
    color: #FFF;
    font-size: 8px;
    font-weight: 700;

    border-radius: 24px;
    background: var(--Linear-Orange, linear-gradient(180deg, #FF9E44 41.67%, #FF891C 62.5%));
    display: inline-flex;
    padding: 2px 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    position: absolute;
    top: 0;
    right: 0;
}

.control {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 10px;
}

.item-control {
    position: relative;
    border-radius: 24px;
    background: #28113D;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.filter {
    position: relative;
}

.list-choice {
    left: 2px;
    position: absolute;
    width: 97%;
    color: var(--Liquorice-Dark, #4C4C4C);
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.24px;
    border-radius: 4px;
    box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.15);
    width: 220px;
    z-index: 3;
}

.list-choice .item-choice {
    /* color: var(--Pumpkin-Light, #FC7A1B); */
    color: var(--Liquorice-Dark, #4C4C4C);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.24px;


    height: 40px;
    padding: 16px;
    background: var(--Creme-Light, #FFF);
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    border: 1px solid var(--Pumpkin-Very-Light, #dddcdc);
}

.list-choice .item-choice p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-choice .item-choice:hover {
    background: var(--Pumpkin-Very-Light, #FFE9DA);
}

.filter .label {
    color: var(--squid-black, #1D1D1F);
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.24px;
}

.input-filter>p {
    color: var(--Creme-Light, #585656);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.32px;
    margin-right: 5px;
}

.input-filter>.p-active {
    color: var(--Liquorice-Dark, #4C4C4C);
    font-weight: 700;
}

.input-filter {
    border-radius: 8px;
    border: 1px solid var(--Cloud-Dark, #CFCFCF);
    background: var(--Creme-Light, #FFF);
    box-shadow: 2px 2px 0px 0px #CFCFCF;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-right: 3px;
    width: 220px;
}

.input-filter.active {
    border: 1px solid var(--Pumpkin-Light, #FC7A1B);
    background: var(--Pumpkin-Very-Light, #FFE9DA);
    box-shadow: 2px 2px 0px 0px #FC7A1B;
}

.no-result {
    padding: 32px 24px;
    color: var(--Squid-Light, #403D3D);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.28px;
}

.item-content-search {
    padding: 16px 0;
    border-bottom: 1px solid var(--Cloud-Light, #DDD);
    background: #FFF;
}

.item-content-search h5 {
    color: var(--Liquorice-Light, #777);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    padding: 0 24px;
}

.item-content-search .result-search {
    color: var(--Squid-Light, #403D3D);
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.32px;
    margin-top: 16px;
    padding: 16px 40px;
}

.item-content-search .result-search:hover {
    background: rgba(234, 212, 255, 0.50);
    cursor: pointer;
}

.content-search {
    border-radius: 24px;
    background: var(--Creme-Light, #FFF);
    height: 100%;
    overflow-y: auto;
}

.option-search {
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
}

.option-search .selection.active {
    color: var(--Creme-Light, #FFF);
    border: 2px solid var(--Blueberry-Dark, #2376A8);
    background: var(--Blueberry-Light, #02A6E7);
}

.option-search .selection {
    border-radius: 24px;
    border: 2px solid var(--Cloud-Light, #DDD);
    background: var(--Creme-Light, #FFF);
    padding: 8px 16px;
    cursor: pointer;

    color: var(--squid-black, #1D1D1F);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
}

.header-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.header-search h4 {
    color: var(--Jelly-Light, #A560EB);
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.48px;
}


.input-search{
    display: flex;
    border-radius: 30px;
    height: 45px;
    border: 1px solid var(--Cloud-Dark, #CFCFCF);
    background: var(--Creme-Light, #FFF);
    flex: 1;
}

.model-search .search {
    height: 48px;
    border-radius: 48px;
    /* border: 1px solid var(--Cloud-Dark, #CFCFCF); */
    /* background: var(--Creme-Light, #FFF); */
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
}

.model-search .line {
    margin-top: 16px;
    border-bottom: 1px solid #DDD;
    margin-bottom: 16px;
}

.model-search .form-search {
    width: 85%;
    color: var(--squid-black, #1D1D1F);
    font-size: 14px;
    font-weight: 600;
    background: #FFF;
}

.model-search {
    border-radius: 24px 0 0 24px;
    background: var(--Creme-Dark, #F8FAFC);
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    transition: all 0.3s !important;
    transform: translateX(100%);
    padding: 24px;
    padding-bottom: 190px;
    overflow: hidden;
    z-index: 100;
}

.model-search.active {
    width: 50%;
    transform: translateX(0);
}

#home {
    transition: all 0.5s !important;
}

.item-assignment .btn-play {
    cursor: pointer;
    width: 90px;
}

.item-assignment.today .day {
    color: #FC7A1B;
}

.item-assignment .day {
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.02em;
    display: flex;
    align-items: center;
    margin: 0 0 0 6.5px;
}

.item-assignment .info-assignment-right {
    display: flex;
    align-self: center;
    /* color: ; */
}

.item-assignment .number {
    letter-spacing: 0.05em;
    font-weight: 700;
    font-size: 20px;
    color: #F8FAFC;
}

.item-assignment .img-assignment {
    margin: 0 16px;
    overflow: hidden;
    width: 65px;
    height: 65px;
    border-radius: 16px;
}

.item-assignment .img-assignment img {
    width: 100%;
    height: 100%;
}

.item-class.finish .calender-class {
    background: #777777;
}

.item-class.finish {
    color: #CFCFCF;
}

.item-class.finish .status {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.05em;
    margin-right: 28px;
}

.item-class.comming .status p:nth-child(1) {
    font-weight: 400;
    font-size: 16px;
}

.item-class.comming .status p:nth-child(2) {
    font-weight: 700;
    font-size: 20px;
}

.item-class.comming .status {
    margin-right: 28px;
    color: #FDD20D;
    text-align: center;
    letter-spacing: 0.02em;
}

.today_classes .time-start {
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.today_classes .list-classes {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.assignments .list-assignments>div {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.today_classes .item-class,
.assignments .item-assignment {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assignments .item-assignment {
    height: 64px !important;
}

.today_classes .info-class,
.assignments .info-assignment {
    display: flex;
    align-items: center;
}

.today_classes .calender-class {
    width: 90px;
    height: 90px;
    background: #FFC317;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    margin-right: 24px;
}

.today_classes .calender-class .day {
    font-weight: 700;
    font-size: 14px;
    color: #000000;
    margin-bottom: 5px;
}

.today_classes .calender-class .time {
    font-weight: 700;
    font-size: 16px;
    color: #334155;
    width: 86px;
    height: 64px;
    background-color: #FFFFFF;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer .name-subject {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 2px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    max-width: 250px;
}

.footer .teacher,
.footer .name-theme {
    font-weight: 400;
    font-size: 14px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    max-width: 250px;

}

.details-class p {
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow:ellipsis;
    max-width: 250px;
}

.footer .time-start,
.footer .name-unit {
    font-weight: 500;
    font-size: 16px;
}

.footer {
    gap: 42px;
    margin-top: 40px;
    margin-bottom: 26px;
}

.footer .line {
    width: 100%;
    border: 1px solid #fff;
    margin: 16px 0;
}

.footer .title {
    font-weight: 700;
    font-size: 22px;
    letter-spacing: 0.02em;
    text-wrap: nowrap;
}

.footer .today_classes {
    width: 50%;
}

.footer .assignments {
    width: 100%;
}

.subject-card {
    width: 260px;
    min-width: 260px;
    height: 282px;
    /* background: radial-gradient(109.13% 100% at 50% 100%, #CC97EF 0%, #BA53EA 100%); */
    background: #FFF;
    border-radius: 24px;
    padding: 16px 22px;
    overflow: hidden;
    cursor: pointer;
}

.subject-card .div-btn {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.subject-card .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    /* height: 64px; */
    margin-bottom: 8px;
}

/* .subject-card .switch{
        width: 16px;
        height: 100%;
        background: rgba(0, 175, 255, 0.15);
        border-radius: 1000px;
    } */
.subject-card .title {
    font-weight: 600;
    font-size: 14px;
    color: #4C4C4C;
    letter-spacing: 0.02em;
    max-width: 100%; 
    white-space: nowrap;
    text-overflow: ellipsis;
    transition: all 0.5s ease;
}

.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; 
}

/* .header.marquee:hover .title {
    text-overflow: unset; 
    animation: marquee 5s linear infinite;
} */

.header.marquee .title {
    text-overflow: unset; /* Bỏ dấu ... */
    animation: marquee 5s linear infinite;
}

@keyframes marquee {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-250%);
    }
}

.subject-card .status {
    font-weight: 700;
    font-size: 20px;
    letter-spacing: 0.05em;
    text-align: center;
    margin-top: 28px;
}

.subject-card .status.comming {
    color: #DDDDDD;
}

.subject-card .studing {
    font-weight: 700;
    font-size: 16px;
    color: #4C4C4C;
    text-align: center;
    /* height: 16px; */
    padding-top: 8px;
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.subject-card .precent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 800;
    font-size: 12px;
    color: #fff;
    /* opacity: 0.48; */
    width: max-content;
}

.subject-card .progress {
    position: relative;
    opacity: 1;
    background-color: #EEF0F7;
    border-radius: 48px;
    height: 24px;
    width: 216px;
    padding: 3px;
    margin: 14px 0 12px 0;
}

.subject-card .progress-load {
    background: #58CC02;
    border-radius: 48px;
    transition: none 0s ease 0s;
    height: 100%;
    width: 5%;
}

.subject-card .not-start .progress-load {
    background: #DDDDDD;
}

.subject-card .inprogress .progress-load {
    background: #58CC02;
}

.subject-card .inprogress .precent{
    color: #fff;
}

.subject-card .expired .progress-load {
    background: #DDDDDD;
}

.subject-card .expired .precent{
    color: #777777;
}

.subject-card .not-start .precent {
    color: #777777
}

.subject-card .to-do .precent {
    color: #FC7A1B;
}

.subject-card .to-do .progress-load {
    background: #FFE9DA;
}

.subject-card .complete .progress-load {
    background: #A560EB;
}

.subject-card img {
    width: -webkit-fill-available;
    height: 120px;
    border-radius: 24px;
}

#home {
    /* margin-top: 25px; */
    /* width: 1088px; */
    overflow: hidden;
}

.hello {
    font-weight: 500;
    font-size: 24px;
}

.hello h2 {
    font-size: 40px;
    font-weight: 900;
    letter-spacing: 0.02em;
}

.search form {
    display: flex;
    background: #fff;
    border-radius: 30px;
    height: 40px;
}

.search .icon-search {
    width: 16px;
    margin: 0 16px;
}



.search .form-search {
    outline: none;
    border: none;
    font-size: 12px;
    margin-right: 8px;
    border-radius: 48px;
}

.search .btn-search {
    outline: none;
    border: none;
    background: #F8FE5F;
    border-radius: 0 30px 30px 0;
    padding: 0 16px;
    font-size: 12px;
    cursor: pointer;
}

.content {
    display: grid;
    grid-template-columns: repeat(auto-fit, 260px);
    /* display: flex; */
    gap: 16px;
    justify-items: center; 
    /* overflow-x: auto; */
}

.subject-img {
    display: flex;
    justify-content: center;
}

.pacing .line {
    width: 1px;
    height: 24px;
    border: 1px dashed #000;
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    z-index: 0;
    top: -25%;
}

.pacing .status-switch {
    position: absolute;
    border-radius: 1000px;
    background: #FF9839;
    width: 16px;
    height: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 0;
}

.pacing .status-1 {
    right: 0%;
}

.pacing .status-2 {
    right: 37%;
}

/* .pacing .status-1{
        position: absolute;
        top: 50%;
        z-index: 1;
        border-radius: 0px 0px 1000px 1000px;
        background: rgba(0, 175, 255, 0.15);
        width: 16px;
        height: 24px;
    }
    .pacing .status-2{
        position: absolute;
        bottom: 50%;
        z-index: 1;
        border-radius: 1000px 1000px 0px 0px;
        background: rgba(0, 175, 255, 0.15);
        width: 16px;
        height: 24px;
    } */
.pacing .switch {
    width: 64px;
    height: 16px;
    /* padding: 0px 32px 0px 8px; */
    border-radius: 1000px;
    background: rgba(0, 175, 255, 0.15);
    position: relative;
    /* transform: rotate(-90deg); */
    /* margin-top: 5px; */
}

.no-classes {
    color: var(--jelly-very-light, #EAD4FF);
    font-size: 24px;
    font-weight: 700;
    display: flex;
    margin-top: 50px;
    align-items: center;
    justify-content: center;
}

.courses-list {
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    /* justify-content: space-between; */
}

.courses-list-empty {
    border: 2px solid var(--cloud-light, #DDD);
    background-color: white;
    border-radius: 24px;
    padding-bottom: 16px;
    height: 260px;
    width: 100%;
    flex-direction: column;
    align-items: center;
    display: flex;
    align-self: stretch;
    justify-content: center;
}

.courses-list-empty .icon {
    /* width: 200; */
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.courses-list-empty .title {
    text-align: center;
    font-weight:
        400;
    font-size: 16px;
    color: #CFCFCF;
    letter-spacing: 0.02em;
}

.course-title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 24px */
    letter-spacing: 0.4px;
    text-wrap: nowrap;
}

.course-programs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
}

.course-programs>p {
    color: var(--Creme-Light, #FFF);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
}

.course-programs .program-card {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    align-self: stretch;
    border-radius: 24px;
    border: 2px solid var(--cloud-light, #DDD);
    background: var(--creme-light, #FFF);
    color: var(--squid-black, #191715);
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    cursor: pointer;
    box-sizing: border-box;
}

.course-programs .program-card.active {
    color: #fff;
    border: 2px solid var(--blueberry-dark, #2376A8);
    background: var(--blueberry-light, #02A6E7);
    box-sizing: border-box;
}

.btn-search1{
    cursor: pointer;
    background: #A560EB;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    box-shadow: 4px 4px 0px 0px #8549BA;
    padding: 16px 24px;
    border-radius: 48px;
    border: 1px solid #8549BA;
    width: 99px;
}

.btn-search1:active {
  position: relative;
  box-shadow: none;
  top: 4px;
  left: 4px;
}

.btn-search1.active {
  position: relative;
  box-shadow: none;
  top: 4px;
  left: 4px;
}

.btn-search1.disable
{
    border: 1px solid var(--Cloud-Dark, #CFCFCF);
    background: var(--Cloud-Light, #DDD);
    box-shadow: 4px 4px 0px 0px #CFCFCF;
    cursor: not-allowed;
    pointer-events: none;
}

.btn-search.disable
{
    border: 1px solid var(--Cloud-Dark, #CFCFCF);
    background: var(--Cloud-Light, #DDD);
    cursor: not-allowed;
}
.modal1{
    position: fixed;
    z-index: 99;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    backdrop-filter: blur(12px);
}
</style>